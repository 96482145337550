import React, { Component } from 'react';
import { connect } from 'react-redux';
import StrategicFuelMain from './StrategicFuelMain';
import AcknowledgmentModal from '../../actions/AcknowledgmentModal';
import * as restActions from '../../actions/restActions';
import { bindActionCreators } from 'redux';
import AdvancedSearchBar from '@bnsf/bnsf-react-adv-search-bar';
 
const generateFiltersData = (
    initialData,
    stationData,
    selectedInitial,
    selectedStation,
    handleValidation,
    fuelPlanData,
    selectedLocoNumb
) => {
    const filtersData = [
        {
            field: 'initial',
            type: 'select',
            value: selectedInitial || 'BNSF',
            items: [{ label: 'Initials Selected', value: '' }, ...initialData.map((item) => ({ label: item, value: item }))],
            customClassNames: 'large-select',
        },
        {
            type: 'number',
            field: 'locoNbr',
            pattern: '^[0-9]{3,4}$',
            validationMessage: 'Only Numericals allowed, Allowed Length Min:3 Max:4',
            onValidation: (message) => handleValidation(message),
            disabled: false,
            placeholder: 'Locomotive Number',
            customClassNames: 'large-input',
        },
        {
            field: 'station',
            type: 'select',
            defaultValue: selectedStation || '',
            placeholder: 'Station',
            items: [{ label: 'Station', value: '' }, ...stationData.map((item) => ({ label: item, value: item }))],
            customClassNames: 'large-select',
        },
    ];
 
    if (fuelPlanData && fuelPlanData.consistInfo && fuelPlanData.consistInfo.aiLocoConsistInfo) {
        filtersData.push({
            field: 'locoNumbDropdown',
            type: 'select',
            value: selectedLocoNumb || '',
            placeholder: 'Select Locomotive Number',
            items: [
                { label: 'Locomotive Number', value: '' },
                ...fuelPlanData.consistInfo.aiLocoConsistInfo.map((item) => ({
                    label: item.trnSeqNumb !== null ? `${item.trnSeqNumb} ${item.locoInit} ${item.locoNumb}` : `${item.locoInit} ${item.locoNumb}`,
                    value: item.trnSeqNumb !== null ? `${item.trnSeqNumb} ${item.locoInit} ${item.locoNumb}` : `${item.locoInit} ${item.locoNumb}`,
                    initials: item.locoInit,
                    locomotiveNumber: item.locoNumb,
                })),
            ],
            disabled: false,
            customClassNames: 'large-select',
        });
    }
 
    return filtersData;
};
 
const filterActionsData = (searchClick, resetBtnClick, resetIconClick) => [
    {
        label: 'Search',
        action: 'search',
        className: 'btn btn-raised btn-primary ml-2 text-capitalize  px-12 py-1 common-sm btn-default',
        disabled: false,
        onClickCallback: (data) => searchClick(data),
    },
    {
        label: 'Reset',
        action: 'reset',
        className: 'btn btn-raised btn-secondary ml-2 text-capitalize  px-12 py-1 common-sm btn-default',
        disabled: false,
        onClickCallback: () => resetBtnClick(),
    },
    {
        label: 'Refresh',
        action: 'search',
        disabled: false,
        className: 'btn btn-raised btn-secondary ml-2 text-capitalize  px-2 py-1 common-sm btn-default',
        icon: 'undo',
        onClickCallback: () => resetIconClick(),
    },
];
 
class FuelPlan extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            viewData: false,
            showModal: false,
            isAcknowledged: false,
            initials: '',
            locomotiveNumber: '',
            station: '',
            stationData: [],
            initialData: [],
            errorMessage: '',
            selectedInitial: '',
            selectedStation: '',
            navExpanded: true,
            formValues: [],
            selectedLocoNumb: '', 
        };
 
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }
 
    async componentDidMount() {
        try {
            const stationsData = await this.props.restActions.fetchStations();
            if (stationsData && Array.isArray(stationsData)) {
                const updatedStationData = stationsData.map((station) => station.stnDtl).sort();
                this.setState({ stationData: updatedStationData });
            } else {
                console.error('stationsData is not an array:', stationsData);
            }
 
            const initialsResponse = await this.props.restActions.fetchInitials();
            if (initialsResponse && Array.isArray(initialsResponse)) {
                const updatedInitialsData = initialsResponse.map((item) => item.valName).sort();
                this.setState({ initialData: updatedInitialsData });
                this.setState({ formValues: generateFiltersData(
                    updatedInitialsData,
                    this.state.stationData,
                    this.state.selectedInitial,
                    this.state.selectedStation,
                    this.handleValidation,
                    this.props.fuelPlanData,
                    this.state.selectedLocoNumb 
                ) });
            } else {
                console.error('initialsResponse is not an array:', initialsResponse);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
 
    componentDidUpdate(prevProps) {
        if (this.props.fuelPlanData?.consistInfo?.aiLocoConsistInfo) {            
            // Create new formValues array
            let newFormValues = this.state.formValues.filter(filter => filter.field !== 'locoNumbDropdown');
            
            // Add locomotive dropdown
            newFormValues.push({
                field: 'locoNumbDropdown',
                type: 'select',
                value: this.state.selectedLocoNumb || '', 
                placeholder: 'Select Locomotive Number',
                items: [
                    { label: 'Locomotive Number', value: '' },
                    ...this.props.fuelPlanData.consistInfo.aiLocoConsistInfo.map((item) => ({
                        label: item.trnSeqNumb ? `${item.trnSeqNumb} ${item.locoInit} ${item.locoNumb}` : `${item.locoInit} ${item.locoNumb}`,
                        value: item.trnSeqNumb ? `${item.trnSeqNumb} ${item.locoInit} ${item.locoNumb}` : `${item.locoInit} ${item.locoNumb}`,
                    })),
                ],
                disabled: false,
                customClassNames: 'large-select',
            });
    
            // Only update state if formValues have changed
            if (JSON.stringify(newFormValues) !== JSON.stringify(this.state.formValues)) {
                this.setState({ formValues: newFormValues });
            }
        } else {
            // Remove the dropdown if consistInfo is null
            const newFormValues = this.state.formValues.filter(filter => filter.field !== 'locoNumbDropdown');
            
            // Only update state if formValues have changed
            if (JSON.stringify(newFormValues) !== JSON.stringify(this.state.formValues)) {
                this.setState({ formValues: newFormValues });
            }
        }
    }
 
    onApplyFilters(values) {
        console.log('values***:', values);
        if (!values.initial || !values.station || !values.locoNbr) {
            this.setState({ errorMessage: 'All fields (Initials, Locomotive number & Stations) are mandatory' });
            return;
        }
    
        let locomotiveNumber = values.locoNumbDropdown || values.locoNbr;
    
        // Extract only the locomotive number if locoNumbDropdown contains a value like '1 BNSF 7658' or 'BNSF 7658'
        if (values.locoNumbDropdown) {
            const parts = values.locoNumbDropdown.split(' ');
            locomotiveNumber = parts[parts.length - 1];
        }
    
        this.setState({
            initials: values.initial,
            locomotiveNumber: locomotiveNumber,
            station: values.station,
            showModal: true,
            isAcknowledged: false,
            viewData: false,
            errorMessage: '',
            selectedInitial: values.initial,
            selectedStation: values.station,
            navExpanded: false,
        });
    }
   
    handleFieldChange = (field, value) => {
        if (field === 'locoNumbDropdown') {
            this.setState({ selectedLocoNumb: value });
    
            // Extract initials and locomotive number from the selected value
            const parts = value.split(' ');
            const locomotiveNumber = parts[parts.length - 1];
            const initials = parts.length === 3 ? parts[1] : parts[0];
    
            // Update locoNbr and initial fields with the extracted values
            this.setState(prevState => {
                const newFilters = prevState.formValues.map(filter => ({ ...filter }));
                
                const locoNbrIndex = newFilters.findIndex(filter => filter.field === 'locoNbr');
                if (locoNbrIndex !== -1) {
                    newFilters[locoNbrIndex].value = locomotiveNumber;
                }
    
                const initialsIndex = newFilters.findIndex(filter => filter.field === 'initial');
                if (initialsIndex !== -1) {
                    newFilters[initialsIndex].value = initials;
                }
    
                return { formValues: newFilters };
            });
        }
    
        this.setState(prevState => {
            // Create a new array from the previous formValues
            const newFilters = prevState.formValues.map(filter => ({ ...filter }));
    
            // Update the value for the matching field
            const fieldIndex = newFilters.findIndex(filter => filter.field === field);
            if (fieldIndex !== -1) {
                newFilters[fieldIndex].value = value;
            }
    
            const locoNbrDropdownIndex = newFilters.findIndex(filter => filter.field === 'locoNumbDropdown');
            const initialsIndex = newFilters.findIndex(filter => filter.field === 'initial');
    
            // If locoNbr changes and is not empty, clear locoNumbDropdown (if it exists) and set initials to BNSF
            if (field === 'locoNbr' && value) {
                if (locoNbrDropdownIndex >= 0) {
                    newFilters[locoNbrDropdownIndex].value = '';
                    this.setState({ selectedLocoNumb: '' });
                }
    
                if (initialsIndex !== -1) {
                    newFilters[initialsIndex].value = 'BNSF';
                }
            }
    
            // Return the new state
            return {
                formValues: newFilters
            };
        });
    };
 
 
    handleResetFilters = () => {
        this.setState({
            viewData: false,
            errorMessage: '',
            selectedInitial: '',
            selectedStation: '',
            locomotiveNumber: '',
        });
    };
 
    handleResetIconFilters = () => {
        const { initials, station, locomotiveNumber } = this.state;
        const searchData = {
            initial: initials,
            station: station,
            locoNbr: locomotiveNumber,
        };
        this.setState({ viewData: false }, () => {
            this.onApplyFilters(searchData);
        });
    };
 
    hasEmptyValue = (obj) => {
        return Object.values(obj).some((value) => value !== '');
    };
 
    handleAcknowledge = () => {
        this.setState({
            isAcknowledged: true,
            showModal: false,
            viewData: true,
        });
    };
    handleValidation = (message) => {
        this.setState({ errorMessage: message });
    };
    render() {
        const {
            viewData,
            isAcknowledged,
            initials,
            locomotiveNumber,
            station,
            showModal,
            errorMessage,
            formValues,
            navExpanded
        } = this.state;
        return (
            <React.Fragment>
                <div className="strategic-fuel-container">
                    <AdvancedSearchBar
                        filters={formValues}
                        filterActions={filterActionsData(
                            this.onApplyFilters,
                            this.handleResetFilters,
                            this.handleResetIconFilters
                        )}
                        onApplyFilters={this.onApplyFilters}
                        navExpanded={this.state.navExpanded}
                        onChangeCallback={this.handleFieldChange}
                    />
                </div>
                {errorMessage && (
                    <div
                        className="error-message"
                        style={{ color: 'red', textAlign: 'left', marginLeft: '20%', fontSize: '18px' }}
                    >
                        {errorMessage}
                    </div>
                )}
                <div>
                    {viewData && isAcknowledged ? (
                        <StrategicFuelMain initials={initials} locomotiveNumber={locomotiveNumber} station={station} />
                    ) : null}
                </div>
                {showModal && (
                    <AcknowledgmentModal
                        show={showModal}
                        handleClose={() => this.setState({ showModal: false })}
                        handleAcknowledge={this.handleAcknowledge}
                    />
                )}
            </React.Fragment>
        );
    }
}
 
const mapDispatchToProps = (dispatch) => {
    return {
        restActions: bindActionCreators(restActions, dispatch),
 
    };
};
 
const mapStateToProps = (state) => {
    return {
        interchangeStations: state.stationView.stationsData,
        initials: state.stationView.initialsData,
        tokenInfo: state.helper.tokenInfo,
        fuelPlanData: state.stationView.fuelPlanData,
    };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(FuelPlan);