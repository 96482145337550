import React from 'react';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';
import Root from './components/Root';
import './index.css';

const { container } = global;

const render = () => {
    try {
        const root = createRoot(document.getElementById('container'));
        root.render(<Root />);
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            // TODO show an application error display
        }
        throw e;
    }
};

render();
