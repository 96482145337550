import { errorTypes } from '../errorHandler/errorTypes';

export const applicationError = {
    code: '',
    message: '',
    type: '',
};
export const station = {
    station: '',
    stn333: '',
    state: '',
    stn33State: '',
};
export const customError = (errorobj, msg) => {
    switch (errorobj.code) {
        case '500':
        case '401':
        case '403':
            applicationError.type = errorTypes.ERROR;
            break;
        default:
            applicationError.type = errorTypes.SUCCESS;
            break;
    }
    applicationError.code = errorobj.code;

    applicationError.message = msg ? msg : errorobj.message;
    return applicationError;
};
