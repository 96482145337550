import { assign } from 'lodash';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    tokenInfo: { accessToken: [], roles: [], username: [] },
    loading: false,
    envVariables: {},
    hasError: false,
    error: {},
    showErrorModal: false,
    showSuccessPopup: false,
    popupSuccessMessage: null,
    oidcCredentials: {},
    userData: {},
    userRoles: {},
};

const helperReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_USER_TOKEN_INFO:
            return {
                ...state,
                tokenInfo: action.userTokenInfo,
            };
        case actionTypes.FETCH_AEI_MAINTENANCE_ERROR:
            return {
                ...state,
                error: action.error,
                hasError: action.hasError,
                loading: false,
            };
        case actionTypes.STORE_ENV_VARS:
            return {
                ...state,
                envVariables: action.envVariables,
            };
        case actionTypes.CLOSE_ERROR_POPUP:
            return {
                ...state,
                hasError: false,
            };
        case actionTypes.HANDLE_SPINNER:
            return {
                ...state,
                loading: action.loading,
            };
        case actionTypes.HANDLE_SUCCESS_POPUP:
            return {
                ...state,
                showSuccessPopup: action.showSuccessPopup,
                popupSuccessMessage: action.popupSuccessMessage,
            };

        case actionTypes.GET_OIDC_CREDENTIALS:
            return assign({}, state, {
                oidcCredentials: action.payload.response.data,
            });
        case actionTypes.USER_LOADED:
            return assign({}, state, {
                userData: action.payload.userData,
            });
        case actionTypes.USER_ROLES:
            return assign({}, state, {
                userRoles: action.payload.userRoles,
            });

        default:
            return state;
    }
};

export default helperReducer;
