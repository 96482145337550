import React from 'react';
import '../../index';
const footer = (props) => (
    <div className="navbar navbar-inverse material-1 footer" style={{ zIndex: '10' }}>
        <div className="container-fluid" style={{ marginTop: '10px' }}>
            <div className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-center">
                    <li className="dropup " style={{ color: 'white' }}>
                        Copyright @ 2024 BNSF Railway Company. All Rights Reserved.
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default footer;
