import React from 'react';

const AcknowledgmentModal = ({ show, handleClose, handleAcknowledge }) => {
    if (!show) return null;

    return (
        <div classname="modal" style={{ display: 'block' }}>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" style={{ fontWeight: 'bold' }}>Acknowledgment Required</h4>
                        <button type="button" class="close" onClick={handleClose} aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                    <p style={{ fontSize: '1.4em' }}>Please Verify the Current Amt and Fuel To Values Carefully.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={handleClose}>Close</button>
                        <button type="button" class="btn btn-primary" onClick={handleAcknowledge}>Acknowledge</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcknowledgmentModal;