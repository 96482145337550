import React from 'react';
import { NavLink } from 'react-router-dom';
import * as urls from '../../util/appUrls';
import './header.css';
import logo from './bnsf-logo.png'; 

const Header = (props) => {
    return (
        <nav className="gel4-header">
            <div className="gel4-header__logo">
                <NavLink to={urls.APP_BASE_URL + '?reload=true'}>
                    <img
                        className="gel4-header__logo-image"
                        src={logo}
                        alt="BNSF LOGO"
                    />
                    <span className="gel4-header__title">Strategic Fueling</span>
                </NavLink>
            </div>
            <div className="gel4-header__user-info">
                <i className="fa fa-user-circle fa-2x" /> &nbsp;
                <span className="gel4-header__user-name">
                    {props.loggedInuser.profile.given_name} ({props.loggedInuser.profile.sub})
                </span>
            </div>
        </nav>
    );
};

export default Header;