// src/redux/store.js
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import stationViewReducer from '../reducers/stationViewReducer';
import helperReducer from '../reducers/helperReducer';
import thunk from 'redux-thunk';

const restoreState = () => {
    try {
        const serializedState = localStorage.getItem('redux-state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.warn(`Failed to load state from local storage`);
        return undefined;
    }
};

const rootReducer = combineReducers({
    helper: helperReducer,
    stationView: stationViewReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)), restoreState(), applyMiddleware());

global.store = store;

export default store;

export const dispatch = store.dispatch;
