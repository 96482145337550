import React from 'react';
const Spinner = (props) => {
    return (
        <div className="spinner" align="center">
            <div className="progress-spinner">
                <div className="spinner-container">
                    <div className="spinner-clipper left">
                        <div className="spinner" />
                    </div>
                    <div className="spinner-clipper right">
                        <div className="spinner" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Spinner;
