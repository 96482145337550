export const LOCAL_ENV_BASE_URL = 'https://vmcxxdsp165.rails.rwy.bnsf.com:4300';
export const ENV_BASE_URL = window.location.origin;
export const OIDC_CREDENTIALS_URL = '/api/configui/getOidcCredentials';
export const ENV_URL = '/config/react-service-urls.js';
export const BASE_URL = '/domain/refdata/';
export const INITIALS = BASE_URL + 'LOCOINIT';
export const APP_BASE_URL = '/';
export const LOCAL_ENV = 'development';
export const FUEL_BASE_URL = 'https://lf-sf-main-api-dev.lf-sf-service-nonprod.bnsf.com';
export const STATIONS_URL = '/fuel/ddstndetail';
export const INITIALS_URL = '/domain/refdata/LOCOINIT';
export const WITRONIX_URL = '/wiTronix/loco-MostRecentInfo/';
export const INIT_URL = '/init/';
export const LOCO_URL = '/loco/';
export const STN333_URL = '/stn333/';
export const STNST_URL = '/stnst/';
export const USER_ID_URL = '/reqby/';
