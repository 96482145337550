import uuid from 'uuid';
import { getAccessToken } from '../actions/helperActions';
import { FUEL_BASE_URL } from '../util/appUrls';
import { getServiceUrlForMaintAPI } from '../actions/helperActions';

let accessToken = '';

export const get = (url) => {
    accessToken = getAccessToken();
    const mainAPIurl = getServiceUrlForMaintAPI();
    url = mainAPIurl + url;
    var responseData = null;
    responseData = fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            correlationId: uuid.v4(),
            Authorization: `Bearer ${accessToken}`,
        },
    }).then((response) => {
        if (response.ok) return response.json();
        else throw response;
    });
    return responseData;
};
