export const STORE_USER_TOKEN_INFO = 'STORE_USER_TOKEN_INFO';
export const STORE_SITE_ID = 'STORE_SITE_ID';
export const STORE_ENV_VARS = 'STORE_ENV_VARS';

export const FETCH_STATIONS_SUCCESS = 'FETCH_STATIONS_SUCCESS';
export const FETCH_INITIALS_SUCCESS = 'FETCH_INITIALS_SUCCESS';
export const WITRONIX_READING_SUCCESS = 'WITRONIX_READING_SUCCESS';
export const FETCH_FUEL_PLAN_SUCCESS = 'FETCH_FUEL_PLAN_SUCCESS';

//General
export const HANDLE_SPINNER = 'HANDLE_SPINNER';
export const HANDLE_SUCCESS_POPUP = 'HANDLE_SUCCESS_POPUP';
export const CLOSE_ERROR_POPUP = 'CLOSE_ERROR_POPUP';

export const GET_OIDC_CREDENTIALS = 'OIDC_CREDENTIALS';

export const USER_LOADED = 'USER_LOADED';

export const USER_ROLES = 'USER_ROLES';
export const FETCH_AEI_MAINTENANCE_ERROR = 'FETCH_AEI_MAINTENANCE_ERROR';
