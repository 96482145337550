import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import { MemoryRouter as Router, Route } from 'react-router-dom';
import App from '../components/App';

const Root = (props) => {
    return (
        <Router>
            <Provider store={store}>
                <Route exact path="/" component={App} />
            </Provider>
        </Router>
    );
};

export default Root;
