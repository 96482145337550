import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import './strategicfuel.css';
import * as restActions from '../../actions/restActions';
import { bindActionCreators } from 'redux';
import StrategicFuelModal from './StrategicFuelModal';


class StrategicFuelMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planLastRefreshed: '',
            trainId: '',
            mostRecentStation: '',
            nextRefuelStation: '',
            fuelTo: '',
            currentAmt: '',
            dispense: '',
            fuelPlanData: [],
            showPage: false,
            showModal: false,
            timestampOfDataUTC: '',
            locoInit: '',
            locoNumber: '',
            fuelLevel: 0,
            loading: false,
        };
    }

    async componentDidMount() {
        const { initials, locomotiveNumber, station, userData } = this.props;
        const userId = userData?.profile?.sub?.trim();
        // Ensure station is defined and properly formatted
        const stationValue = station || '';
        const [stn333, stnst] = stationValue.split(',').map((s) => s.trim());

        try {
            const response = await this.props.restActions.fetchFuelPlan(
                initials.trim(),
                locomotiveNumber.trim(),
                stn333,
                stnst,
                userId
            );
            this.setState({ fuelPlanData: response });
    
            if (response && Object.keys(response).length > 0) {
                const setStateWithResponse = () => {
                    this.setState({
                        trainId: response.trnid,
                        mostRecentStation: response.currentStation,
                        nextRefuelStation: response.nextStation,
                        fuelTo: response.outb_gal,
                        currentAmt: response.inb_gal,
                        dispense: response.amt_to_disp,
                        planLastRefreshed: new Date(response.timestamp).toLocaleString(),
                        showPage: true,
                    });
                };
    
                const showAlert = (message, callback) => {
                    Swal.fire({
                        title: '<strong style="text-align: left;">WARNING</strong>',
                        html: `<b style="font-size: 18px;">${message}</b>`,
                        icon: 'error',
                        confirmButtonText: '<span style="font-size: 18px;">Acknowledge</span>',
                        width: '600px',
                    }).then(callback);
                };
    
                const showConfirm = (message) => {
                    Swal.fire({
                        title: '<strong style="text-align: left;">WARNING</strong>',
                        html: `<b style="font-size: 17px;">${message}</b>`,
                        icon: 'warning',
                        showCancelButton: true,
                        cancelButtonText: '<span style="font-size: 18px;">Back</span>',
                        confirmButtonText: '<span style="font-size: 18px;">Proceed</span>',
                        width: '600px',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.cancel) {
                            this.setState({ showPage: false });
                        } else {
                            this.setState({ showPage: true });
                            setStateWithResponse();
                        }
                    });
                };
    
                const { message } = response;
                if (message) {
                    const errorHandlers = {
                        'error[01]: ': () =>
                            showAlert(
                                'Train Symbol not found for current locomotive. Default fuel plans generated',
                                setStateWithResponse
                            ),
                        'error[02]: ': () =>
                            showAlert(
                                'Train Symbol for current locomotive is terminating. Default fuel plans generated',
                                setStateWithResponse
                            ),
                        'error[06]: ': () => handleFuelError(),
                        'error[11]: ': () => handleFuelError(),
                        'error[08]: ': () =>
                            showAlert(
                                'Verify train has arrived at station before requesting fuel plans',
                                setStateWithResponse
                            ),
                        'error[03]: ': () => {
                            const nextRefuelLocation =
                                message.split('next planned refueling location (')[1]?.split(')')[0] ||
                                'Unknown location';
                            const [selectedStation, selectedState] = station.split(',');
                            showConfirm(
                                `Selected locomotive ${initials} ${locomotiveNumber} is not currently planned for 
                                <span style="color: blue; font-weight: bold;">${selectedStation}_${selectedState}</span>. 
                                Next fueling location for this locomotive is 
                                <span style="color: green; font-style: italic;">${nextRefuelLocation}</span>`
                            );
                        },
                    };
    
                    const handleFuelError = () => {
                        if (response.inb_gal !== null && response.outb_gal !== null) {
                            this.setState({
                                dispense: response.amt_to_disp,
                                currentAmt: response.inb_gal,
                                fuelTo: response.outb_gal,
                                planLastRefreshed: new Date(response.timestamp).toLocaleString(),
                                showPage: true,
                            });
                        } else {
                            this.setState({
                                dispense: '0',
                                currentAmt: 'UNKNOWN',
                                fuelTo: 'N/A',
                                planLastRefreshed: new Date(response.timestamp).toLocaleString(),
                                showPage: true,
                            });
                        }
                        showAlert(
                            'Locomotive has no available HP during next segment. Verify fuel plan before proceeding',
                            setStateWithResponse
                        );
                    };
    
                    for (const [error, handler] of Object.entries(errorHandlers)) {
                        if (message.includes(error)) {
                            handler();
                            return;
                        }
                    }
    
                    setStateWithResponse();
                } else {
                    setStateWithResponse();
                }
            } else {
                console.error('Failed to fetch fuel plan or response is empty:', response);
            }
        } catch (error) {
            console.error('Error fetching fuel plan:', error);
        }
    }

    formatTrainId(trainId) {
        if (!trainId) return '';
        const firstPart = trainId.slice(0, 1);
        const middlePart = trainId.slice(1, -3);
        const lastPart = trainId.slice(-3);
        return `${firstPart} ${middlePart} ${lastPart}`;
    }
    handleCurrentFuelReading = async () => {
        if (this.state.loading) return; // Prevent multiple calls
    
        this.setState({ loading: true });
    
        const { initials, locomotiveNumber } = this.props;
        const currentDateTimeUTC = new Date().toISOString(); // Get current date and time in UTC
        try {
            const response = await this.props.restActions.wiTronixCurrentFuelReading(initials, locomotiveNumber, currentDateTimeUTC);
            const { loco, data } = response;
    
            if (data.length === 0) {
                Swal.fire({
                    title: 'No gauge reading found for locomotive',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }
    
            const { timestampOfDataUTC, fuelLevel } = data[0];
            this.setState({
                showModal: true,
                timestampOfDataUTC,
                locoInit: loco.locoInit,
                locoNumber: loco.locoNumber,
                fuelLevel,
            });
        } catch (error) {
            console.error('Error fetching current fuel reading:', error);
        } finally {
            this.setState({ loading: false });
        }
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    handleAcknowledge = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { initials, locomotiveNumber } = this.props;
        const { dispense, fuelTo, currentAmt, trainId, mostRecentStation, nextRefuelStation, planLastRefreshed, showPage, showModal, timestampOfDataUTC, locoInit, locoNumber, fuelLevel } = this.state;
        const highlightFuelTo = !dispense && !currentAmt;

        if (!showPage) {
            return null; // Do not render the page until showPage is true
        }

        return (
            <div className="fuel_container">
                <div className="fuel_sub_container">
                    <div className="fuelplan_wrapper">
                        <h1 className="bold_heading">
                            {initials} {locomotiveNumber} Fuel Plan{' '}
                        </h1>
                        <div className="label_head large_font">
                            <div className="fp_label">Plan Last Refreshed:</div>
                            <div className="fp_label">{planLastRefreshed}</div>
                        </div>
                        <div className="label_head mt_10 large_font">
                            <div className="fp_label">Train Id:</div>
                            <div className="fp_label">{this.formatTrainId(trainId)}</div>
                        </div>
                        <div className="fuelplan_wrapper_sub">
                            <div className="label_head large_font">
                                <div className="fp_label">Most Recent Station</div>
                                <div className="fp_label">{mostRecentStation}</div>
                            </div>
                            <div className="label_head large_font">
                                <div className="fp_label">Next Refuel Station</div>
                                <div className="fp_label">{nextRefuelStation}</div>
                            </div>
                        </div>
                    </div>

                    <div className="fp_wrapper">
                        <div className={`fp_label_value large_font ${highlightFuelTo ? '' : 'fp_border_value'}`}>
                            <div className="fp_label large_font">Dispense</div>
                            <div className="fp_label large_font">
                                {dispense} <span className="fp_units">GAL</span>
                            </div>
                        </div>
                        <div className="fp_label_value large_font">
                            <div className="fp_label large_font">Projected Inbound</div>
                            <div className="fp_label large_font">
                                {currentAmt ? `${currentAmt} ` : 'Unknown '}
                                <span className="fp_units">GAL</span>
                            </div>
                        </div>
                        <div className={`fp_label_value large_font ${highlightFuelTo ? 'fp_border_value' : ''}`}>
                            <div className="fp_label large_font">Fuel To</div>
                            <div className="fp_label large_font">
                                {fuelTo} <span className="fp_units">GAL</span>
                            </div>
                        </div>
                        <div>
                            <button
                                className="btn current_fuel_reading_btn"
                                onClick={this.handleCurrentFuelReading}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? 'Loading...' : 'Check Current Fuel Readings'}
                            </button>
                            {showModal && (
                                <StrategicFuelModal
                                    show={showModal}
                                    handleClose={() => this.setState({ showModal: false })}
                                    handleAcknowledge={this.handleAcknowledge}
                                    timestampOfDataUTC={timestampOfDataUTC}
                                    locoInit={locoInit}
                                    locoNumber={locoNumber}
                                    fuelLevel={fuelLevel}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        restActions: bindActionCreators(restActions, dispatch),
    };
};

const mapStateToProps = (state) => {
    return {
        fuelPlanData: state.stationView.fuelPlanData,
        userData: state.helper.userData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StrategicFuelMain);