import React from 'react';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import Spinner from '../UI/sprinner/Spinner';

const layout = (props) => {
    return (
        <>
            <Header loggedInuser={props.loggedInuser} pathName={props.pathName} />
            <div style={{ height: 'calc(100vh - 17vh)' }}>
                {props.loading ? <Spinner /> : null}
                {props.children}
            </div>
            <Footer />
        </>
    );
};

export default layout;
