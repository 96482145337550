import moment from 'moment';
import { LOCAL_ENV_BASE_URL, ENV_BASE_URL } from '../util/appUrls';
import { LOCAL_ENV } from '../util/appUrls';

export const getBaseUrl = () => {
    return process.env.NODE_ENV === LOCAL_ENV ? LOCAL_ENV_BASE_URL : ENV_BASE_URL;
};
export const RPTG_STN_SEARCH = 'search';
export const isEmptyObject = (obj) => {
    if (Object.keys(obj).length === 0 && obj.constructor === Object) return true;
    return false;
};
