import * as actionTypes from './actionTypes';
import * as helperActions from './helperActions';
import { get } from '../services/serviceClient';
import * as urls from '../util/appUrls';

export const fetchStationsDataSuccess = (response) => {
    return {
        type: actionTypes.FETCH_STATIONS_SUCCESS,
        stationsData: response,
    };
};
export const fetchStations = () => {
    return (dispatch) => {
        dispatch(helperActions.handleSpinner(true));
        return get(urls.STATIONS_URL)
            .then((data) => {
                dispatch(helperActions.handleSpinner(false));
                dispatch(fetchStationsDataSuccess(data));
                return data;
            })
            .catch((error) => {
                dispatch(helperActions.handleSpinner(false));
                helperActions.handleServiceError(error, dispatch);
            });
    };
};
export const fetchInitialsDataSuccess = (response) => {
    return {
        type: actionTypes.FETCH_INITIALS_SUCCESS,
        initialsData: response,
    };
};
export const fetchInitials = () => {
    return (dispatch) => {
        dispatch(helperActions.handleSpinner(true));
        return get(urls.INITIALS_URL)
            .then((data) => {
                dispatch(helperActions.handleSpinner(false));
                dispatch(fetchInitialsDataSuccess(data));
                return data;
            })
            .catch((error) => {
                dispatch(helperActions.handleSpinner(false));
                helperActions.handleServiceError(error, dispatch);
            });
    };
};
export const wiTronixCurrentFuelReadingSuccess = (response) => {
    return {
        type: actionTypes.WITRONIX_READING_SUCCESS,
        wiTronixCurrentFuelReadingData: response,
    };
};
export const wiTronixCurrentFuelReading = (initials, locomotiveNumber, currentDateTimeUTC) => {
    return (dispatch) => {
        dispatch(helperActions.handleSpinner(true));
        return get(
            urls.WITRONIX_URL +
                initials +
                '/' +
                locomotiveNumber +
                '/' +
                currentDateTimeUTC 
        )
            .then((data) => {
                dispatch(helperActions.handleSpinner(false));
                dispatch(wiTronixCurrentFuelReadingSuccess(data));
                return data;
            })
            .catch((error) => {
                dispatch(helperActions.handleSpinner(false));
                helperActions.handleServiceError(error, dispatch);
            });
    };
};
export const fetchFuelPlanSuccess = (response) => {
    return {
        type: actionTypes.FETCH_FUEL_PLAN_SUCCESS,
        fuelPlanData: response,
    };
};
export const fetchFuelPlan = (initials, locomotiveNumber, stn333, stnst, userId) => {
    return (dispatch) => {
        dispatch(helperActions.handleSpinner(true));
       return  get(
            urls.INIT_URL +
                initials +
                urls.LOCO_URL +
                locomotiveNumber +
                urls.STN333_URL +
                stn333 +
                urls.STNST_URL +
                stnst +
                urls.USER_ID_URL +
                userId
        )
            .then((data) => {
                dispatch(helperActions.handleSpinner(false));
                dispatch(fetchFuelPlanSuccess(data));
                return data;
            })
            .catch((error) => {
                dispatch(helperActions.handleSpinner(false));
                helperActions.handleServiceError(error, dispatch);
            });
    };
};
