import React, { useEffect, useState } from 'react';
import './App.css';
import * as urls from '../util/appUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helperActions from '../actions/helperActions';
import { OIDCReactLib } from '@bnsf/oidc-token-lib-react';
import { useOidc } from '@bnsf/oidc-token-lib-react';
import oidcConfigLocalTest from '../util/oidcConfigTestObject';
import FuelPlan from '../components/StrategicFuel/FuelPlan';
import { MemoryRouter as Router, Route } from 'react-router-dom';
import Layout from './layout/layout';

export const App = (props) => {
    const [loading, setLoading] = useState(false);
    const [userDetails, setUser] = React.useState({});
    const [loaded, setLoaded] = React.useState(false);
    const { authUser } = useOidc();

    useEffect(() => {
         props.helperActions.getApiUrls();
    }, []);

    useEffect(() => {
        if (authUser) {
            setUser(authUser);
            setLoading(true);
            props.helperActions.setUserData(authUser);
            props.tokenInfo.accessToken = authUser.access_token;
        }
    }, [authUser]);

    return (
        <div>
            <OIDCReactLib/>
            <div>
                {loading && (
                    <Layout loggedInuser={userDetails} loading={props.loading} pathName={props.location.pathname}>
                        <Route exact path={urls.APP_BASE_URL} component={FuelPlan} />
                    </Layout>
                )}
            </div>
        </div>
    );
};

export const mapStateToProps = (state) => {
    return {
        userData: state.helper.userData,
        loading: state.helper.isLoading,
        oidcCredentials: state.helper.oidcCredentials,
        tokenInfo: state.helper.tokenInfo,
        loading: state.helper.loading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        helperActions: bindActionCreators(helperActions, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
