import React from 'react';

const StrategicFuelModal = ({ show, handleClose, handleAcknowledge, timestampOfDataUTC, locoInit, locoNumber, fuelLevel }) => {
    if (!show) return null;

    const handleAcknowledgeAndClose = () => {
        handleAcknowledge();
        handleClose();
    };

    const convertToCST = (utcDate) => {
        const date = new Date(utcDate);
        const options = { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date) + ' CST';
    };

    const timestampCST = convertToCST(timestampOfDataUTC);

    return (
        <div className="modal" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" style={{ fontWeight: 'bold' }}>
                            Most Recent Fuel Level Reading
                        </h4>
                    </div>
                    <p style={{ fontWeight: 'bold', fontSize: '1.1em', textAlign: 'left' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;As of {timestampCST}
                    </p>
                    <div className="modal-body">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontWeight: 'bold',
                                marginTop: '-15px',
                            }}
                        >
                            <span style={{ fontSize: '2.1em' }}>
                                {locoInit} {locoNumber}
                            </span>
                            <span style={{ fontSize: '2.1em' }}>{fuelLevel} GAL</span>
                        </div>
                        <br />
                        <p style={{ fontSize: '1.4em', textAlign: 'left' }}>
                            * Fuel level take 5 minutes to settle after fueling event. Values presented need to be
                            visually verified{' '}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn acknowledge" onClick={handleAcknowledgeAndClose}>
                            Acknowledge
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrategicFuelModal;