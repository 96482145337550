import axios from 'axios';
import { customError } from '../errorHandler/applicationError';
import * as errorMessages from '../errorHandler/errorMessages';
import * as urls from '../util/appUrls';
import { getBaseUrl } from '../util/helperUtil';
import * as actionTypes from './actionTypes';

let apiUrl = '';
let initialsUrl = '';
let accessToken = '';

export const storeUserTokenInfo = (userTokenInfo) => {
    return {
        type: actionTypes.STORE_USER_TOKEN_INFO,
        userTokenInfo: userTokenInfo,
    };
};

export const storeEnvVariables = (envVariables) => ({
    type: actionTypes.STORE_ENV_VARS,
    payload: envVariables,
  });

export function getApiUrls() {
    return (dispatch) => {
        axios.get(getBaseUrl() + urls.ENV_URL).then((response) => {
            dispatch(storeEnvVariables(response.data));
            apiUrl = response.data.apiUrl;
        }).catch((error) => {
             console.error('Error fetching API URL:', error.message);
        });
    };
}

export function getAccessToken() {
    return accessToken || '';
}

export function getServiceUrlForMaintAPI() {
    return apiUrl || '';
}

export const getOidcCredentials = () => {
    return (dispatch) => {
        axios.get(getBaseUrl() + urls.OIDC_CREDENTIALS_URL).then((response) => {
            dispatch({
                type: actionTypes.GET_OIDC_CREDENTIALS,
                payload: { response },
            });
        });
    };
};

export const setUserData = (userData) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.USER_LOADED, payload: { userData } });
        accessToken = userData.access_token;
    };
};

export const setUserRoles = (userRoles) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.USER_LOADED, payload: { userRoles } });
        userRoles = userRoles;
    };
};

export const setApiError = (error) => {
    return {
        type: actionTypes.FETCH_AEI_MAINTENANCE_ERROR,
        error: error,
        hasError: true,
    };
};

export const handleSpinner = (loadingStatus) => {
    return {
        type: actionTypes.HANDLE_SPINNER,
        loading: loadingStatus,
    };
};
export const closeErrorPopup = () => {
    return {
        type: actionTypes.CLOSE_ERROR_POPUP,
    };
};
export const handleSuccessPopup = (status, message = null) => {
    return {
        type: actionTypes.HANDLE_SUCCESS_POPUP,
        showSuccessPopup: status,
        popupSuccessMessage: message,
    };
};
export const handleServiceError = (error, dispatch) => {
    try {
        error.json().then((errorResponse) => {
            if (Array.isArray(errorResponse.errors)) dispatch(setApiError(errorResponse.errors[0]));
        });
    } catch {
        let applicationError = customError(error, errorMessages.APPLICATION_ERROR);
        dispatch(setApiError(applicationError));
    }
};
