import * as actionTypes from '../actions/actionTypes';


const initialState = {
    fuelPlanData: {},
    stationsData: {},
    initialsData: {},
    wiTronixCurrentFuelReadingData: {}
};

const stationViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STATIONS_SUCCESS:
            return {
                ...state,
                stationsData: action.stationsData,
            };
        case actionTypes.FETCH_INITIALS_SUCCESS:
            return {
                ...state,
                initialsData: action.initialsData,
            };
        case actionTypes.FETCH_FUEL_PLAN_SUCCESS:
            return {
                ...state,
                fuelPlanData: action.fuelPlanData,
            };
        case actionTypes.WITRONIX_READING_SUCCESS:
            return {
                ...state,
                wiTronixCurrentFuelReadingData: action.wiTronixCurrentFuelReadingData,
            };
        default:
            return state;
    }
};


export default stationViewReducer;
